import React from 'react';

class ScrollNav extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();

    this.state = {
      activeLink: 'home'
    }
  }

  componentDidMount() {
    const options = {
      threshold: 0.45
    }

    window.addEventListener('scroll', this.handleScroll);
    this.getElement();

    const observer = new IntersectionObserver(this.handleIntersect, options);
    [/*'companies', 'team',*/ 'contact'].forEach(id => {
      let ele = document.getElementById(id);
      if (ele) {
        observer.observe(ele);
      } else {
        console.warn('Element with id ' + id + ' not found');
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getElement = () => {
    var element = document.getElementById('scroll-nav');
    if (element) {
      this.element = element;
    } else {
      setTimeout(() => {
        this.getElement();
      }, 500)
    }
  }

  handleScroll = () => {
    requestAnimationFrame(this.checkHeight)
  }

  handleIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.45) {
        var id = entry.target.getAttribute('id');
        this.setState({ activeLink: id });
      }
    });
  }

  checkHeight = () => {
    var { heightToScrollAt } = this.props;

    heightToScrollAt = heightToScrollAt ? heightToScrollAt : 164 - 72;

    if (!!this.element) {
      if (window.scrollY >= heightToScrollAt) {
        if (!this.element.classList.contains('scroll-nav')) {
          this.element.classList.remove('hidden-nav');
          this.element.classList.remove('hidden-nav-no-animation');
          this.element.classList.add('scroll-nav');
        }
      } else {
        if (!this.element.classList.contains('hidden-nav') && this.element.classList.contains('scroll-nav')) {
          this.element.classList.remove('scroll-nav');
          this.element.classList.add('hidden-nav');
        }
      }
    }
  }

  render() {
    const { activeLink } = this.state;

    return (
      <div className='hidden-nav-no-animation d-none d-md-block' id='scroll-nav'>
        <h5 className={`${activeLink == 'home' ? 'active ' : ''}side-nav-btn`}>
          <a href='#home'>
            Home
          </a>
        </h5>
        {/*<h5 className={`${activeLink == 'companies' ? 'active ' : ''}side-nav-btn`}>
          <a href='#companies'>
            Our Companies
          </a>
        </h5>*
        <h5 className={`${activeLink == 'team' ? 'active ' : ''}side-nav-btn`}>
          <a href='#team'>
            Our Team
          </a>
        </h5>*/}
        <h5 className={`${activeLink == 'contact' ? 'active ' : ''}side-nav-btn`}>
          <a href='#contact'>
            Contact
          </a>
        </h5>
      </div>
    )
  }
}

export default ScrollNav;