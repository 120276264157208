import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Trans } from 'react-i18next';

import './Home.scss';

class Home extends React.Component {
  render = () => (
    <div className='Home' id='home'>
      <Container>
        <Row className='align-items-center'>
          <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <h1 style={{ fontSize: '40px', fontWeight: '900' }}>
                Kerflummox Capital is an entrepreneurial-minded family company focused on investing, managing, and building innovative small businesses.
              </h1>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home;