import React from 'react';
import './App.scss';

// translations
import './i18n';

import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';

import Home from './pages/Home/Home';
//import Companies from './pages/Companies/Companies';
import Team from './pages/Team/Team';
import Contact from './pages/Contact/Contact';
import Companies from './pages/Companies/Companies';
import ScrollNav from './components/Navigation/ScrollNav';

const App = () => {
  return (
    <div className="App">
      <Navigation />
      <Home />
      {/*<Companies />*/}
      {/*<Team />*/}
      <Contact />
      <ScrollNav heightToScrollAt={500} />
    </div>
  );
}

export default App;
